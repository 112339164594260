$(function() {

    /**
     * 
     * Smooth scroll to hash link
     * 
     */
    $(document).on('click', 'a[href^="#"]', function (event) {
        
        event.preventDefault();

        let divId = $.attr(this, 'href');
        
        if (divId.length <= 1) return false;

        let $target = $($.attr(this, 'href'))

        if ($target.length === 0) return
    
        $('html, body').animate({
            scrollTop: $target.offset().top
        }, 500);
    
    });

    let $categoryFilter = $('[data-category-filter]');
    $categoryFilter.on('change', function (){
        let url = $(this).find('option:selected').data('url')
        window.location = url
    })


})


