import Cookies from 'js-cookie'

var didConsent = false;
var consentStatus = false;
var cookieBar;

var cookieConsent = {

    init: function() {

        window.addEventListener("load", function() {

            let $cookieContentToggle = $('.cookie-settings-toggle');
            $cookieContentToggle.on('click', function(e) {
                e.preventDefault();
                
                if (didConsent) {
                    cookieConsent.clearCookies(true);
                    $('.cookies-disabled').show();
                    $('.cookies-enabled').hide();
                    window.location.reload();
                }

                if(! didConsent) {
                    Cookies.set('cookieconsent_status', 'allow', { path: '/', expires: 365 });
                    cookieConsent.initCookies();
                    $('.cookies-disabled').hide();
                    $('.cookies-enabled').show();
                    window.location.reload();
                }

            });

            window.cookieconsent.initialise({
                "palette": {
                    "popup": {
                        "background": "#ededed"
                    },
                    "button": {
                        "background": "#e00334"
                    }
                },
                "showLink": true,
                "type": "opt-in",
                "content": {
                    "href"   : config.url + "/privacy-policy/",
                    "deny": "Dismiss",
                },
                "elements": {
                    "header": '<span class="cc-header">{{header}}</span>&nbsp;',
                    "message": '<span id="cookieconsent:desc" class="cc-message">{{message}}</span>',
                    "messagelink": '<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a></span>',
                    "dismiss": '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss">{{dismiss}}</a>',
                    "allow": '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">{{allow}}</a>',
                    "deny": '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">{{deny}}</a>',
                    "link": '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a>',
                    "close": '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close">{{close}}</span>',
                },

                /**
                 * onInitialise()
                 *
                 * Only called if a user has dismissed/accepted, confusingly, it's not called when the script is initialised. 
                 * 
                 */
                onInitialise: function(status) {
                    didConsent = this.hasConsented();
                    consentStatus = this.getStatus();

                    if (didConsent && consentStatus !== 'dismiss') {
                        cookieConsent.initCookies();
                    }

                    if (! didConsent) {
                        cookieConsent.clearCookies(true);
                    }


                    cookieConsent.checkCookieSettings();

                },

                /**
                 * onStatusChange()
                 * 
                 */
                onStatusChange: function(status, chosenBefore) {
                    
                    didConsent = this.hasConsented();
                    consentStatus = this.getStatus();

                    if (didConsent && consentStatus !== 'dismiss') {
                        cookieConsent.initCookies();
                    }

                    if (!didConsent) {
                        cookieConsent.clearCookies(false);
                    }

                },

                /**
                 * onRevokeChoice()
                 * 
                 */
                onRevokeChoice: function() {

                },

                /**
                 * onPopupOpen()
                 *
                 * Only called when the popup opens, and if a choice has not been made
                 * 
                 */
                onPopupOpen: function() {
                    didConsent = this.hasConsented();
                    cookieConsent.checkCookieSettings();
                },

            });

        });
    },

    /**
     *
     * Track via Google Analytics
     *
     */
    addAnalytics: function() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', config.gaId, {
            'anonymize_ip': true
        });
    },

    /**
     *
     * Initialise all cookies
     *
     */
    initCookies: function() {
        if (config.env == 'production' && config.loadGa == true && didConsent == true) {
            // Google Analytics
            this.addAnalytics();
        }
    },

    /**
     *
     * Clear all cookies
     *
     */
    clearCookies: function(reload) {

        Cookies.remove('_gat_gtag_UA_176993026_1', { path: '/', domain: '.surepress.psy.com' });
        Cookies.remove('_gid', { path: '/', domain: '.surepress.psy.com' });
        Cookies.remove('_gat', { path: '/', domain: '.surepress.psy.com' });
        Cookies.remove('cookieconsent_status');

        if (reload) {
            window.location.reload();
        }

    },

    /**
     *
     * Create a settings page for cookies
     *  
     */
    checkCookieSettings: function() {
        let $cookieSettingsEnabled = $('.cookies-enabled');
        let $cookieSettingsDisabled = $('.cookies-disabled');
        
        
        if (didConsent && consentStatus !== 'dismiss') {
            $cookieSettingsEnabled.show();
        } else {
            $cookieSettingsDisabled.show();
        }
    }

};

if (config.env === 'production') {
    cookieConsent.init();
}