import "../vendor/jquery.stackmenu.js"

// Get the navigation menu
let $stackMenu = $('[data-stack-menu]')
$stackMenu.stackMenu()

// Avoids the 'flash' as CSS loads.
$stackMenu.removeAttr('style')

// Hook up the hamburger
let $hamburger = $('#hamburger')
$hamburger.on('click', function(e){
    e.preventDefault()
    // Toggle the states
    $(this).toggleClass('is--active')
    $stackMenu.toggleClass('is--active')
    // Toggle message on the label
    let $message = $('[data-hamburger-message]')
    let closeMessage = $message.data('hamburger-message')
    let openMessage = $message.text()
    $message.text(closeMessage)
    $message.data('hamburger-message', openMessage)

    $('body').toggleClass('overflow-hidden')

    if ($(this).hasClass('is--active')) {
        $('[data-mobile-search-container]').addClass('hidden');
    }
    
})