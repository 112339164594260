// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

let $swiper = $('.swiper-container')

$.each($swiper, (index, item) => {
    let swiper = new Swiper(item, {
        // Optional parameters
        direction: $(item).data('direction') ? $(item).data('direction') : 'horizontal',
        loop: $(item).data('loop') ? $(item).data('loop') : true,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
})
