/**
 * Lazy Sizes
 * 
 * @link https://github.com/aFarkas/lazysizes
 * 
 */
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';




